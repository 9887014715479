import axios from "axios";
import UserService from "@/modules/user/services/UserService.vue";
import ToastHelper from "@/helpers/OpenToast.vue";
// import store from "@/store";
import router from "@/router";

const cifDomain = process.env.VUE_APP_MASTERDATA_DOMAIN;
const baseURL = `${cifDomain}`;

const instance = axios.create({
  baseURL: baseURL,
});

instance.interceptors.request.use(function (config) {
  // let authKey = store.state.token;
  // if (!authKey) {
  //   authKey = UserService.authHeader();
  // }
  config.headers["Authorization"] = "Bearer " + UserService.authHeader();
  return config;
});

instance.interceptors.response.use(
  function (response) {
    return response.data;
  },
  function (error) {
    if (error.response.status == 401) {
      router.push('/dang-nhap');
      return Promise.reject(error);
    }
    ToastHelper.OpenToast(error.response.data, "is-danger");
    return Promise.reject(error);
  }
);
export default instance;
