import axios from "axios";
import ToastHelper from "@/helpers/OpenToast.vue";
import router from "@/router";
import store from "@/store";
import UserService from "@/modules/user/services/UserService.vue";

const baseDomain = process.env.VUE_APP_SIGNURL_DOMAIN;
const baseURL = `${baseDomain}`;

const instance = axios.create({
  baseURL: baseURL,
});

instance.interceptors.request.use(function (config) {
  let authKey = store.token;
  if (!authKey) {
    authKey = UserService.authHeader();
  }
  let encodedCodeKey = UserService.encodedCodeHeader();
  config.headers["Authorization"] = "Bearer " + authKey;
  config.headers["EncodedCode"] = encodedCodeKey;
  store.state.isLoading = true;
  return config;
});

instance.interceptors.response.use(
  function (response) {
    store.state.isLoading = false;
    return response.data;
  },
  function (error) {
    store.state.isLoading = false;
    if (error.response.status == 401) {
      router.push('/dang-nhap');
      return Promise.reject(error);
    }
    ToastHelper.OpenToast(error.response.data, "is-danger", 10000);
    return Promise.reject(error);
  }
);
export default instance;
