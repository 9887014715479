<script>
import Repository from "@/repositories/Repository.vue";
import RepositoryMasterData from "@/repositories/Masterdata/Repository.vue";
import RepositoryMifos from "@/repositories/Mifos/Repository.vue";
//import RepositorySQS from "@/repositories/SQS/Repository.vue";

import Enum from "@/helpers/Enum.vue";

const Post_ListFund = async (item) => {
  return await Repository.post(Enum.API.FundTransfer.List, item);
};
const Get_ListStatusFund = async () => {
  return await Repository.get(Enum.API.Category.ListStatusFund);
};
const Get_ListMethodPopup = async (method, ou) => {
  return await Repository.get(
    Enum.API.Category.ListMethodPopup + `?method=${method}&&ou=${ou}`
  );
};
const Get_ListBank = async () => {
  return await RepositoryMasterData.get(Enum.API.MasterData.ListBank);
};
const Get_ListPGD = async () => {
  return await RepositoryMasterData.get(Enum.API.MasterData.ListPGD);
};
const Post_CreateFundTransfer = async (item) => {
  return await Repository.post(Enum.API.FundTransfer.Create, item);
};
const Post_GetFundBalance = async (item) => {
  return await RepositoryMifos.post(Enum.API.Mifos.GetFundBalance, item);
};
const Get_ListMethod = async (method) => {
  return await Repository.get(
    Enum.API.Category.ListMethod + `?method=${method}`
  );
};
const Get_ListImage = async (Id) => {
  return await Repository.get(
    Enum.API.FundTransferImage.List + `?IdFundTransfer=${Id}`
  );
};
const Post_CreateFundImage = async (item) => {
  return await Repository.post(Enum.API.FundTransferImage.Create, item);
};
const Post_ExportExcelFund = async (item) => {
  return await Repository.postDownload(Enum.API.FundTransfer.ExportExcel, item);
};

const Get_ListPGDNew=async()=>{
  return await RepositoryMasterData.get(Enum.API.MasterData.PGDNew);
}
const Post_UpdateStatusMifos=async(item)=>
{
  return await Repository.post(Enum.API.FundTransfer.Update,item);
}
const Post_CreateTran=async(Id,item)=>{
  return await Repository.post(Enum.API.Mifos.CreateTran+`?Id=${Id}`,item);
}
const Get_GetFundById=async(Id)=>{
  return await Repository.get(Enum.API.FundTransfer.Get+`?Id=${Id}`)
}
const Get_GetFundWaitingById=async(Id)=>{
  return await Repository.get(Enum.API.FundTransfer.GetWaiting+`?Id=${Id}`)
}
const GetSumTotalNotApcept=async(shopCode,userName)=>{
  return await Repository.get(Enum.API.FundTransfer.GetSum+`?shopCode=${shopCode}&userName=${userName}`)
}
const GetSumTotalNotApceptByUser=async(shopCode,userName)=>{
  return await Repository.get(Enum.API.FundTransfer.GetListNotApcept+`?shopCode=${shopCode}&userName=${userName}`)
}

const GetImageFromS3=async(url)=>{
  return await Repository.getBase64(url);
}
export default {
  Post_ListFund,
  Get_ListStatusFund,
  Get_ListMethodPopup,
  Get_ListBank,
  Get_ListPGD,
  Post_CreateFundTransfer,
  Post_GetFundBalance,
  Get_ListMethod,
  Get_ListImage,
  Post_CreateFundImage,
  Post_ExportExcelFund,
  //Post_SendEmail,
  Get_ListPGDNew,
  Post_UpdateStatusMifos,
  Post_CreateTran,
  Get_GetFundById,
  Get_GetFundWaitingById,
  GetSumTotalNotApcept,
  GetSumTotalNotApceptByUser,
  GetImageFromS3
};
</script>
