<script>
import Repository from "@/repositories/signurl/Repository.vue";
import Enum from "@/helpers/Enum.vue";

const Get_SignUrl = async (item) => {
  return await Repository.get(Enum.API.Get_SignUrl + `?url=${item}`);
};

export default {
  Get_SignUrl
};
</script>
