<script>
import Repository from "@/repositories/signurl/Repository.vue";
import Enum from "@/helpers/Enum.vue";
const Get_PresignedUrl = async (item) => {
  return await Repository.get(
    Enum.API.Get_PresignedUrl + `?content=fund/${item}`
  );
};

const Put_PresignedUrl = async (url, file) => {
  return await Repository.uploadFile(url, file);
};

export default {
  Get_PresignedUrl,
  Put_PresignedUrl
};
</script>
