<script>
import axios from "axios";
import Client from "./AxiosClient";

const get = (url) => {
  return Client.get(`${url}`);
};

const uploadFile = (url, file) => {
  return axios.put(`${url}`, file, {
    headers: {
      FileName: file?.fileName,
      "Content-Type": file?.type,
      ContentLength: file?.ContentLength,
    },
  });
};

export default {
  get,
  uploadFile
};
</script>