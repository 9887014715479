<script>
// import axios from "axios";
import moment from "moment";
import PresignedUrl from "@/helpers/PresignedUrl.vue";

const getDefaultFileName = (name) => {
  var now = moment();
  var fileName =
    now.format("YYYY") +
    "/" +
    now.format("MM") +
    "/" +
    now.format("YYYYMMDD") +
    "_" +
    now.format("HHmmss") +
    "_" +
    now.format("X") +
    "_" +
    Math.floor(Math.random() * 0x10000).toString(16) +
    "." +
    name.slice(name.lastIndexOf(".") + 1);
  return fileName;
};

const PutFileS3 = async (url, file, fileName = null) => {
  var response = await PresignedUrl.Get_PresignedUrl(url);
  if(response != null || response != undefined){

  if (fileName == null) fileName = getDefaultFileName(file.name);

      return await PresignedUrl.Put_PresignedUrl(response, file);
  }
};
export default {
  PutFileS3
};
</script>
