<script>
import AWS from "aws-sdk";
// import s3 from 'aws-s3';
import moment from "moment";
import Compress from "compress.js";
import store from "@/store";
 var albumBucketName = process.env.VUE_APP_AWS_BucketName;
// var bucketRegion = process.env.VUE_APP_AWS_BucketRegion;
// var IdentityPoolId = process.env.VUE_APP_AWS_IdentityPoolId;
// AWS.config.update({
//   region: bucketRegion,
//   accessKeyId: process.env.AWSAccessKeyId,
//   secretAccessKey: process.env.AWSSecretKey,
//   credentials: new AWS.CognitoIdentityCredentials({
//     IdentityPoolId: "ap-southeast-1:f880ec18-6d17-4dec-bcd1-3c6c7bae759f",
//   }),
// });

const config = {
   bucketName: albumBucketName,
  region: process.env.VUE_APP_AWS_BucketRegion,
  accessKeyId: process.env.VUE_APP_AWS_AWSAccessKeyId,
  secretAccessKey: process.env.VUE_APP_AWS_AWSSecretKey,
  s3Url: process.env.VUE_APP_AWS_URL,
  params: { 
    Bucket: process.env.VUE_APP_AWS_BucketName
  }
}

const getDefaultFileName = (name) => {
  var now = moment();
  var fileName =
    now.format("YYYY") +
    "/" +
    now.format("MM") +
    "/" +
    now.format("YYYYMMDD") +
    "_" +
    now.format("HHmmss") +
    "_" +
    now.format("X") +
    "_" +
    Math.floor(Math.random() * 0x10000).toString(16) +
    "." +
    name.slice(name.lastIndexOf(".") + 1);
  return fileName;
};

const upload = async (file, fileName = null) => {
  if (fileName == null) fileName = getDefaultFileName(file.name);
  let isUploadSuccess = false;

  try {
    const s3 = new AWS.S3(config);
    var files = [file];

    const compress = new Compress();
    await compress
      .compress(files, {
        size: 10, // the max size in MB, defaults to 2MB
        quality: 0.5, // the quality of the image, max is 1,
        //maxWidth: 1920, // the max width of the output image, defaults to 1920px
        //maxHeight: 1920, // the max height of the output image, defaults to 1920px
        resize: false, // defaults to true, set false if you do not want to resize the image width and height
      })
      .then(async (results) => {
        var img1 = results[0];
        var base64str = img1.data;
        var imgExt = img1.ext;
        var blob = Compress.convertBase64ToFile(base64str, imgExt);
        const s3res = await s3.upload({
          partSize: 10 * 1024 * 1024,
          Bucket: albumBucketName,
          Key: fileName,
          Body: blob,
          ContentType:'image/jpeg',
          // Metadata: {
          //   'Content-Type': 'image/jpeg'
          // }
        });

        var promise = s3res.promise();

        await promise.then(
          function (data) {
            isUploadSuccess = true;
            return data;
          },
          function (err) {
            console.log(err);
          }
        );
      });
  }
  catch(err) {
    console.log("ERROR", err);
  }

  if (isUploadSuccess) return fileName;

  return "";
};

const uploadFile = async (file, fileName = null) => {
  if (fileName == null) fileName = getDefaultFileName(file.name);
  let isUploadSuccess = false;
  try {
    const s3 = new AWS.S3(config);

    const s3res = await s3.upload({
      partSize: 10 * 1024 * 1024,
      queueSize: 10,
       Bucket: albumBucketName,
      Key: fileName,
      Body: file,
      //ContentType:'image/jpeg',
    });

    var promise = s3res.promise();
    await promise.then(
      function (data) {
        isUploadSuccess = true;
        
        return data;
      },
      function (err) {
        console.log(err);
      }
    );
  }
  catch (err) {
    console.log("ERROR", err);
  }
  
  if (isUploadSuccess) return fileName;

  return "";
};

const deleteFile = (pathOfFile) => {
  var fOk = false;
  var del = new AWS.S3(config);
  del.deleteObject({ Key: pathOfFile }, function (err) {
    if (err) {
      console.log(err);
    }
    fOk = true;
  });
  return fOk;
};

const getFile =async (patchFile,token)=>{
  //var fOk =null;
  let data=null
  config.params.ContinuationToken=token;
  var get = new AWS.S3(config);
  //console.log(token)
  let fOk =await get.getObject({Key:patchFile},function(err,file){
     if (err) {
      console.log(err,file);
    }
    //console.log("file",file.Body);
    //fOk= file.Body;
    store.state.fileUpload=fOk.response.data.Body;
    console.log("file",store.state.fileUpload);
    return data;
  })
  data = store.state.fileUpload
  return data;
}
export default {
  upload,
  uploadFile,
  deleteFile,
  getFile
};
</script>
